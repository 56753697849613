import React, { useState } from 'react'
import { Link } from 'gatsby'
import SEO from '../components/seo'
import AuthForm from '../components/AuthForm/AuthForm'
import {
  createShopifyCustomer,
  authenticateShopifyCustomer,
} from '../helpers/requests'
import Cookies from 'js-cookie'

const RegisterPage = () => {
  const [authErrorMessage, setAuthErrorMessage] = useState('')
  const submitForm = values => {
    createShopifyCustomer(values.email, values.password).then(res => {
      console.log(res)
      const { customerCreate } = res.data.data
      if (!customerCreate.userErrors.length) {
        authenticateShopifyCustomer(values.email, values.password).then(res => {
          // set cookie with access token / exp.date
          console.log(res)
          const { customerAccessTokenCreate } = res.data.data
          if (!customerAccessTokenCreate.userErrors.length) {
            const {
              accessToken,
              expiresAt,
            } = customerAccessTokenCreate.customerAccessToken
            console.log(accessToken, expiresAt)
            const expiryDate = new Date(expiresAt)
            Cookies.set('KEPT_SESS', accessToken, { expires: expiryDate })
            window.location.href = '/account'
          } else {
            setAuthErrorMessage(customerAccessTokenCreate.userErrors[0].message)
            setTimeout(() => setAuthErrorMessage(''), 10000)
          }
        })
      } else {
        setAuthErrorMessage(customerCreate.userErrors[0].message)
        setTimeout(() => setAuthErrorMessage(''), 10000)
      }
    })
  }
  return (
    <div>
      <SEO title="Sign Up" />
      <div className="container text-center">
        <div className="row no-gutters pt-50 collection-header">
          <h1 className="ma u-h0">Sign Up</h1>
        </div>
      </div>
      <AuthForm
        type="register"
        submitForm={submitForm}
        authErrorMessage={authErrorMessage}
        setAuthErrorMessage={setAuthErrorMessage}
      />
      <div className="auth-form tr">
        <Link to="/login">Login instead</Link>
      </div>
    </div>
  )
}

export default RegisterPage
